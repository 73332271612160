<div class="vstack light" style="width: calc(40vw)">
  <div class="container p-1 header">
    @if (isFilterOpened) {
      <app-filter
        [applyFilter]="appliedFilter.value"
        [workers]="workers"
        [workTypes]="workTypes"
        [villages]="villages"
        (filter)="onFilterChanged($event)"></app-filter>
    } @else {
      <button mat-icon-button
              class="ms-1"
              (click)="onRefreshClicked()"
              container="body"
              ngbTooltip="{{ 'task.refresh' | translate }}">
        <mat-icon>sync</mat-icon>
      </button>
      <button mat-icon-button
              class="ms-1 me-auto"
              (click)="onFilterOpenClicked()"
              container="body"
              ngbTooltip="{{ 'task.filter.filterButtonText' | translate }}">
        <mat-icon>filter_alt</mat-icon>
      </button>

      <button
        mat-icon-button
        class="ms-1"
        [matMenuTriggerFor]="menu"
        container="body"
        ngbTooltip="{{ 'task.filter.sort' | translate }}">
        <mat-icon>swap_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        @for (dropdownitem of sortDropdown; track dropdownitem.id) {
          <button mat-menu-item
              (click)="onSortClicked(dropdownitem.id)">
            {{ dropdownitem.name }}
          </button>
        }
      </mat-menu>

      <button mat-icon-button
              class="ms-1"
              (click)="onAddTaskClicked(createTaskDialog)"
              container="body"
              ngbTooltip="{{ 'task.create' | translate }}">
        <mat-icon>add</mat-icon>
      </button>
    }
    <mat-chip-set #filterChipSet>
      @for (chip of filterChips; track $index) {
        <mat-chip-row (removed)="removeFilterBy(chip.field)">
          {{ chip.text }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
    </mat-chip-set>
  </div>
  <div>
    @for (task of tasks$ | async; track task.id) {
      <div class="card card-body border m-2"
           [ngClass]="{
             'task-item-selected': selectedTaskId === task.id,
             'task-item': selectedTaskId !== task.id,
             'border-5': task.isLastTask
           }"
           (click)="onTaskClicked(task)">
        <div class="row g-0">
          <div class="col-4 d-flex flex-column">
            <h3 [ngbTooltip]="task.id.toString()" (click)="onVillageClicked(task)">{{ task.village.name }}</h3>
            <p
              [ngbTooltip]="'task.workTypeTooltip' | translate ">{{ 'task.workType.' + task.workType | translate }}</p>
            @if (task.worker) {
              <h4 [ngbTooltip]="'task.workerTooltip' | translate ">{{ task.worker.name }}</h4>
            }
            @if (!task.worker && task.status !== TaskStatus.CANCELLED) {
              <ng-select [items]="workers"
                         bindLabel="name"
                         bindValue="id"
                         placeholder="{{ 'task.newTask.workerPlaceholder' | translate }}"
                         (change)="onWorkerAssigned($event, task)"
                         dropdownPosition="auto"
                         class="me-2 mb-2">
              </ng-select>
            }
            <div class="btn-group w-50 mt-auto">
              <button [ngClass]="getButtonStyle(task)" type="button">
                {{ 'task.status.' + TaskStatus[task.status] | translate }}
              </button>
              <button [ngClass]="getToggleButtonStyle(task)"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
              </button>
              <ul class="dropdown-menu">
                @for (action of task.actions; track action.action) {
                  <li>
                    <a class="dropdown-item" (click)="onActionSelected(action.action,task)">{{ action.name }}</a>
                  </li>
                }
              </ul>
            </div>
          </div>
          <div class="col-3">
            @if (selectedTaskId === task.id) {
              <div class="mt-2">
                @if (task.assignedOn) {
                  <p class="time" [ngbTooltip]="'task.assignedOnTooltip' | translate ">
                    <i class="fa-solid fa-cart-plus fa-xl fa-fw"></i>
                    {{ task.assignedOn | date:"dd/MM HH:mm" }}
                  </p>
                } @else {
                  <p class="time" [ngbTooltip]="'task.assignedOnTooltip' | translate ">
                    <i class="fa-solid fa-cart-plus fa-xl fa-fw"></i>
                    -----
                  </p>
                }

                @if (task.fetchedOn) {
                  <p class="time" [ngbTooltip]="'task.fetchedOnTooltip' | translate ">
                    <i class="fa-solid fa-download fa-xl fa-fw"></i>
                    {{ task.fetchedOn | date:"dd/MM HH:mm" }}
                  </p>
                } @else {
                  <p class="time" [ngbTooltip]="'task.fetchedOnTooltip' | translate ">
                    <i class="fa-solid fa-download fa-xl fa-fw"></i>
                    -----
                  </p>
                }
              </div>
            }

            @if (task.startedOn) {
              <p class="time" [ngbTooltip]="'task.startedOnTooltip' | translate ">
                <i class="fa-solid fa-hourglass-start fa-xl fa-fw"></i>
                {{ task.startedOn | date:"dd/MM HH:mm" }}
              </p>
            } @else {
              <p class="time" [ngbTooltip]="'task.startedOnTooltip' | translate ">
                <i class="fa-solid fa-hourglass-start fa-xl fa-fw"></i>
                -----
              </p>
            }

            @if (task.completedOn) {
              <p class="time" [ngbTooltip]="'task.completedOnTooltip' | translate ">
                <i class="fa-solid fa-hourglass-end fa-xl fa-fw"></i>
                {{ task.completedOn | date:"dd/MM HH:mm" }}
              </p>
            } @else {
              <p class="time" [ngbTooltip]="'task.completedOnTooltip' | translate ">
                <i class="fa-solid fa-hourglass-end fa-xl fa-fw"></i>
                -----
              </p>
            }

            @if (task.duration) {
              <p class="time" [ngbTooltip]="'task.durationTooltip' | translate ">
                <i class="fa-regular fa-clock fa-xl fa-fw"></i>
                {{ task.duration | durationFormat }}
              </p>
            }
          </div>
          <div class="col">
            <p>
              <img [src]="'assets/svg/water-source-new-info.svg'" alt="">
              <strong [ngbTooltip]="'task.new' | translate ">
                {{ task.waterSourceCount!.new }}
              </strong>
              <span [ngbTooltip]="'task.total' | translate ">({{ task.waterSourceCount!.total }})</span>
            </p>

            @if (task.workType === WorkType.TREAT || task.workType === WorkType.SURVEY || task.workType === WorkType.QA_SURVEY) {
              <p>
                <img [src]="'assets/svg/water-source-treated-info.svg'" alt="">
                <strong [ngbTooltip]="'task.treatedPercentage' | translate ">
                  {{ task.treatedWaterSource!.percentage }}%
                </strong>
                <span [ngbTooltip]="'task.new' | translate ">({{ task.treatedWaterSource!.count }})</span>
              </p>
            }

            @if (task.workType === WorkType.SAMPLE
            || task.workType === WorkType.QA_TREAT
            || task.workType === WorkType.SURVEY) {
              <p>
                <img [src]="'assets/svg/water-source-sampled-info.svg'" alt="">
                <strong [ngbTooltip]="'task.sampledPercentage' | translate ">
                  {{ task.sampledWaterSource!.percentage }}%
                </strong>
                <span [ngbTooltip]="'task.new' | translate ">({{ task.sampledWaterSource!.count }})</span>

                <img [src]="'assets/svg/water-source-sampled-positive-info.svg'" alt="" class="ms-2">
                <strong [ngbTooltip]="'task.sampledPositivePercentage' | translate " container="body">
                  {{ calculatePercentage(task.sampledWaterSource!.count, task.sampleResult!.positiveCount) }}%
                </strong>
                <span [ngbTooltip]="'task.new' | translate ">({{ task.sampleResult!.positiveCount }})</span>
              </p>
            }
            @if (selectedTaskId === task.id || task.workType === WorkType.TREAT || task.workType === WorkType.SAMPLE) {
              <p>
                <img [src]="'assets/svg/water-source-issued-info.svg'" alt="">
                <strong [ngbTooltip]="'task.issuedPercentage' | translate ">
                  {{ task.issuedWaterSource.percentage }}%
                </strong>
                <span [ngbTooltip]="'task.new' | translate ">({{ task.issuedWaterSource.count }})</span>
              </p>
            }
            @if (task.surveyed) {
              <p>
                <i class="fa-solid fa-table-cells fa-xl fa-fw" style="color: #FBC13B"></i>
                {{
                  'task.surveyedArea' | translate: {
                    area: task.surveyed.area,
                    percentage: task.surveyed.percentage
                  }
                }}
              </p>
            }
            @if (task.score) {
              <p class="score" [ngbTooltip]="'task.score' | translate">
                @if (task.workType === WorkType.TREAT) {
                  <img [src]="'assets/svg/water-source-treated-issued-info.svg'" alt="">
                } @else if (task.workType === WorkType.SAMPLE) {
                  <img [src]="'assets/svg/water-source-sampled-issued-info.svg'" alt="">
                }
                <strong>{{ task.score }}%</strong>
              </p>
            }
          </div>
        </div>
        @if (selectedTaskId === task.id) {
          <div class="top-divider mt-2 pt-2">
            <div class="row row-cols-4 gx-2">
              @if ((task.workType === WorkType.TREAT || task.workType === WorkType.SURVEY || task.workType === WorkType.QA_SURVEY)
              && task.treatedWaterSource && task.treatedWaterSource.count > 0) {
                <div class="col-auto">
                  <p>
                    <img [src]="'assets/svg/water-source-treated-info.svg'" alt="">
                    <strong [ngbTooltip]="'task.treatedPercentage' | translate ">
                      {{ task.treatedWaterSource!.percentage }}%
                    </strong>
                    <span [ngbTooltip]="'task.new' | translate ">({{ task.treatedWaterSource!.count }})</span>
                  </p>
                  <table class="table-info table-striped table-bordered treat-table">
                    <tbody>
                      @for (treatByAction of task.treatsByAction; track treatByAction.action) {
                        <tr>
                          <th scope="row" class="p-1">{{ 'treatAction.' + treatByAction.action | translate }}</th>
                          <td class="p-1">{{ treatByAction.percentage }}% ({{ treatByAction.count }})</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              }
              @if ((task.workType === WorkType.SAMPLE || task.workType === WorkType.QA_TREAT
                || task.workType === WorkType.SURVEY || task.workType === WorkType.QA_SURVEY)
              && task.sampledWaterSource && task.sampledWaterSource.count > 0) {
                <div class="col-auto">
                  <table class="table-info table-striped sample-table">
                    <thead>
                    <tr>
                      <th scope="col" style="background-color: transparent">
                        <p>
                          <img [src]="'assets/svg/water-source-sampled-info.svg'" alt="">
                          <strong
                            [ngbTooltip]="'task.sampledPercentage' | translate ">
                            {{ task.sampledWaterSource!.percentage }}%
                          </strong>
                          <span [ngbTooltip]="'task.new' | translate ">({{ task.sampledWaterSource!.count }})</span>
                        </p>
                      </th>
                      <th scope="col"><i class="fa-solid fa-plus fa-fw"></i></th>
                      <th scope="col"><i class="fa-solid fa-minus fa-fw"></i></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row">{{ 'sample.anopheles' | translate }}</th>
                      <td>{{ task.sampleResult?.anophelesPositiveCount }}</td>
                      <td>{{ task.sampleResult?.anophelesNegativeCount }}</td>
                    </tr>
                    <tr>
                      <th scope="row" class="ps-4">{{ 'sample.anophelesL1_2' | translate }}</th>
                      <td>{{ task.sampleResult?.anophelesL1_2PositiveCount }}</td>
                      <td>{{ task.sampleResult?.anophelesL1_2NegativeCount }}</td>
                    </tr>
                    <tr>
                      <th scope="row" class="ps-4">{{ 'sample.anophelesL3_4' | translate }}</th>
                      <td>{{ task.sampleResult?.anophelesL3_4PositiveCount }}</td>
                      <td>{{ task.sampleResult?.anophelesL3_4NegativeCount }}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{ 'sample.culex' | translate }}</th>
                      <td>{{ task.sampleResult?.culexPositiveCount }}</td>
                      <td>{{ task.sampleResult?.culexNegativeCount }}</td>
                    </tr>
                    <tr>
                      <th scope="row" class="ps-4">{{ 'sample.culexL1_2' | translate }}</th>
                      <td>{{ task.sampleResult?.culexL1_2PositiveCount }}</td>
                      <td>{{ task.sampleResult?.culexL1_2NegativeCount }}</td>
                    </tr>
                    <tr>
                      <th scope="row" class="ps-4">{{ 'sample.culexL3_4' | translate }}</th>
                      <td>{{ task.sampleResult?.culexL3_4PositiveCount }}</td>
                      <td>{{ task.sampleResult?.culexL3_4NegativeCount }}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{ 'sample.pupae' | translate }}</th>
                      <td>{{ task.sampleResult?.pupaePositiveCount }}</td>
                      <td>{{ task.sampleResult?.pupaeNegativeCount }}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <th scope="row">{{ 'sample.any' | translate }}</th>
                    <td><strong>{{ task.sampleResult?.positiveCount }}</strong></td>
                    <td><strong>{{ task.sampleResult?.negativeCount }}</strong></td>
                    </tfoot>
                  </table>
                </div>
              }
              @if ((task.workType === WorkType.SURVEY || task.workType === WorkType.TREAT
                || task.workType === WorkType.SAMPLE || task.workType === WorkType.QA_SURVEY
                || task.workType === WorkType.QA_TREAT) && task.issuedWaterSource.count > 0) {
                <div class="col-auto">
                  <p>
                    <img [src]="'assets/svg/water-source-issued-info.svg'" alt="">
                    <strong class="me-1"
                            [ngbTooltip]="'task.issuedPercentage' | translate ">
                      {{ task.issuedWaterSource.percentage }}%
                    </strong>
                    <span [ngbTooltip]="'task.new' | translate ">({{ task.issuedWaterSource.count }})</span>
                  </p>

                  <table class="table-info table-striped issue-table">
                    <tbody>
                      @for (issueByType of task.issuesByType; track issueByType.type) {
                        <tr>
                          <th scope="row" class="p-1">{{ 'issueType.' + issueByType.type | translate }}</th>
                          <td class="p-1">{{ issueByType.percentage }}% ({{ issueByType.count }})</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              }
              @if ((task.houseStats.new.visited && task.houseStats.new.visited > 0)
              || (task.houseStats.new.notAtHome && task.houseStats.new.notAtHome > 0)
              || (task.houseStats.new.rejected && task.houseStats.new.rejected > 0)
              || task.houseStats.existing.length > 0) {
                <div class="col-auto">
                  <table id="houseInfo"
                         class="table-info table-striped house-table">
                    <tbody>
                      @if (task.houseStats.new.visited && task.houseStats.new.visited > 0) {
                        <tr>
                          <th scope="row" class="align-items-center p-1"
                              container="body"
                              [ngbTooltip]="'task.houseStatus.visited' | translate ">
                            <img [src]="'assets/svg/house-visited.svg'" alt="" class="d-inline-block me-1 img-big">
                          </th>
                          <td><strong>{{ task.houseStats.new.visited }}</strong></td>
                        </tr>
                      }
                      @if (task.houseStats.new.notAtHome && task.houseStats.new.notAtHome > 0) {
                        <tr>
                          <th scope="row" class="img-big align-items-center p-1"
                              container="body"
                              [ngbTooltip]="'task.houseStatus.not_at_home' | translate ">
                            <img [src]="'assets/svg/house-not-at-home.svg'" alt="" class="d-inline-block me-1 img-big">
                          </th>
                          <td><strong>{{ task.houseStats.new.notAtHome }}</strong></td>
                        </tr>
                      }
                      @if (task.houseStats.new.rejected && task.houseStats.new.rejected > 0) {
                        <tr>
                          <th scope="row" class="align-items-center p-1"
                              container="body"
                              [ngbTooltip]="'task.houseStatus.access_denied' | translate ">
                            <img [src]="'assets/svg/house-forbidden.svg'" alt="" class="d-inline-block me-1 img-big">
                          </th>
                          <td><strong>{{ task.houseStats.new.rejected }}</strong></td>
                        </tr>
                      }
                      @for (existing of task.houseStats.existing; track existing.startStatus) {
                        <tr [ngbTooltip]="getExistingHouseStatTooltip(existing)">
                          <th scope="row" class="d-flex align-items-center">
                            <p>
                              @switch (existing.startStatus) {
                                @case (Status.VISITED) {
                                  <img [src]="'assets/svg/house-visited.svg'" alt="" class="me-1 img-big">
                                }
                                @case (Status.NOT_AT_HOME) {
                                  <img [src]="'assets/svg/house-not-at-home.svg'" alt="" class="me-1 img-big">
                                }
                                @case (Status.REFUSED_1) {
                                  <img [src]="'assets/svg/house-forbidden.svg'" alt="" class="me-1 img-big">
                                }
                              }
                            </p>
                            <i class="fa-solid fa-arrow-right justify-content-center"></i>
                            <p>
                              @switch (existing.completeStatus) {
                                @case (Status.VISITED) {
                                  <img [src]="'assets/svg/house-visited.svg'" alt="" class="me-1 img-big">
                                }
                                @case (Status.NOT_AT_HOME) {
                                  <img [src]="'assets/svg/house-not-at-home.svg'" alt="" class="me-1 img-big">
                                }
                                @case (Status.REFUSED_1) {
                                  <img [src]="'assets/svg/house-forbidden.svg'" alt="" class="me-1 img-big">
                                }
                              }
                            </p>
                          </th>
                          <td><strong>{{ existing.count }}</strong></td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              }
            </div>
          </div>
        }
      </div>
    }
  </div>
  @if (loading$ | async) {
    <div class="custom-loader-img-local"></div>
  }
</div>
<ng-template #createTaskDialog let-modal>
  <div class="modal-header">
    <h1 class="modal-title fs-5">{{ 'task.newTask.title' | translate }}</h1>
    <button (click)="modal.dismiss()"
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="newTaskForm">
      <div class="form-group">
        <label for="workType">{{ 'task.newTask.workType' | translate }}</label>
        <ng-select (change)="onWorkTypeSelected($event)"
                   [items]="workTypes"
                   bindLabel="name"
                   bindValue="id"
                   formControlName="workType"
                   id="workType"
                   placeholder="{{ 'task.newTask.workTypePlaceholder' | translate }}">
        </ng-select>
        <label class="mt-3" for="village">{{ 'task.newTask.village' | translate }}</label>
        <ng-select formControlName="villageId"
                   id="village"
                   placeholder="{{ 'task.newTask.villagePlaceholder' | translate }}">
          @for (village of newTaskVillages; track village.id) {
            <ng-option [value]="village.id" [disabled]="village.disabled">
              {{ village.name }}
            </ng-option>
          }
        </ng-select>
        <label class="mt-3" for="worker">{{ 'task.newTask.worker' | translate }}</label>
        <ng-select [items]="workers"
                   bindLabel="name"
                   bindValue="id"
                   formControlName="workerId"
                   id="worker"
                   placeholder="{{ 'task.newTask.workerPlaceholder' | translate }}">
        </ng-select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button mat-button
            (click)="modal.dismiss()"
            data-bs-dismiss="modal"
            type="button">{{ 'task.newTask.cancel' | translate }}
    </button>
    <button mat-flat-button
            (click)="modal.close('submit')"
            [disabled]="!newTaskForm.valid"
            type="submit">{{ 'task.newTask.save' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #assignWorkerDialog let-modal let-taskType="taskType" let-userName="userName">
  <div class="modal-header">
    <h1 class="modal-title fs-5">{{ 'task.assignWorker.title' | translate }}</h1>
    <button (click)="modal.dismiss()"
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"></button>
  </div>
  <div class="modal-body">
    <p>{{
        'task.assignWorker.message' | translate: {
          user: assignWorkerDialogInfo.userName,
          taskType: assignWorkerDialogInfo.taskType
        }
      }}</p>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()" class="btn btn-secondary" data-bs-dismiss="modal"
            type="button">{{ 'task.assignWorker.cancel' | translate }}
    </button>
    <button (click)="modal.close('submitAssignment')" class="btn btn-primary" type="submit">
      {{ 'task.assignWorker.assign' | translate }}
    </button>
  </div>
</ng-template>
