import {Component, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Campaign} from "../../../core/CampaignAdapter";
import {VillageService} from "../../../village/village.service";
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  merge,
  Observable,
  of,
  OperatorFunction,
  Subject,
  Subscription,
  switchMap,
} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import * as _ from 'lodash';
import {CampaignService} from "../../../campaign/campaign.service";
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {Village} from "../../../core/VillageAdapter";
import {LocationService} from "../../../location/location.service";
import {environment} from "../../../../environments/environment";
import {debounceTime} from "rxjs/operators";
import {bbox, polygon} from "@turf/turf";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
  model: any;

  @ViewChild('instance', {static: true}) instance: NgbTypeahead | undefined;

  focus$ = new Subject<string>();
  click$ = new Subject<any>();
  campaigns: Campaign[];
  villages: Village[];
  selectedCampaign: number | undefined;
  @Input() campaigns$: Observable<Campaign[]>;
  @Input() villages$: Observable<Village[]>;
  @Output() searchSelected: Subject<{ bbox: number[] | undefined, center: number[] | undefined }> =
    new Subject<{ bbox: number[] | undefined, center: number[] | undefined }>();

  public text: string = '';
  public open: boolean = false;
  isSearchVisible: boolean = false;
  private villagesSub: Subscription;
  private campaignsSub: Subscription;
  private isSearchActive: boolean = false;

  constructor(private campaignService: CampaignService,
              private villageService: VillageService,
              private locationService: LocationService,
              private cookieService: CookieService,
              private router: Router,) {
  }

  formatter = (x: any) => x.name;

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.isSearchVisible = event.url === '#' || event.url === '/' ||  event.url === '/main' || event.url.startsWith('/?')|| event.url.startsWith('/main?');

      if (!event.url.startsWith('/?center')) {
        if (this.isSearchActive) {
          this.searchSelected.next({bbox: undefined, center: undefined});
          this.isSearchActive = false;
        }
      }
    });
    this.campaignsSub = this.campaigns$.pipe(
      map((campaigns: Campaign[]) =>
        _.sortBy(campaigns, item => item.name.toLowerCase())
      )).subscribe((campaigns: Campaign[]) => {
      this.campaigns = campaigns;
    });
    this.villagesSub = this.villages$.pipe(
      map((villages: Village[]) =>
        _.sortBy(villages, ['name'])
      )).subscribe((villages: Village[]) => {
      this.villages = villages;
    });
    this.campaignService.selectedCampaign$.subscribe((campaign) => {
      this.selectedCampaign = campaign?.id;
      this.instance?.writeValue(undefined);

      if (campaign) {
        this.router.navigate(['/']);
      }
    })
    this.campaignService.getCampaigns();
  }

  ngOnDestroy(): void {
    this.campaignsSub.unsubscribe();
    this.villagesSub.unsubscribe();
  }

  onCampaignSelected(campaign: Campaign | undefined) {
    this.instance?.writeValue(undefined);

    if (campaign) {
      this.campaignService.selectCampaign(campaign);
      this.router.navigate(['/']);
    }
  }

  search: OperatorFunction<string, readonly {
    name: string,
    location?: string,
    bbox?: number[],
    center?: number[],
    isVillage: boolean
  }[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(600),
      distinctUntilChanged(),
      map((searchText) => {
        let trimmedText: { text: string, normalized: { lat: number, lon: number } | undefined, isManual: boolean } = { text: searchText, normalized: undefined, isManual: false };

        if (isLatLong(trimmedText.text)) {
          const latLong = convertToDd(trimmedText.text);

          trimmedText = { text: searchText, normalized: latLong, isManual: true };
        }

        return trimmedText;
      }),
      switchMap((searchText) => {
        const villagesObservable = this.villages$.pipe(
          map((villages: Village[]) => {
            return villages
              .filter((village) =>
                !searchText.isManual && village.name.toLowerCase().indexOf(searchText.text.toLowerCase()) > -1)
              .map((village) => {
                return {name: village.name, bbox: bbox(polygon(village.cords)), center: village.center, isVillage: true}
              });
          }),
        );

        if (!searchText.isManual) {
          if (searchText.text !== '') {
            return combineLatest([villagesObservable,
              this.locationService.getLocations(searchText.text, environment.MAPBOX_ACCESS_TOKEN).pipe(
                map((locations) => locations.map((location) => {
                  return {
                    name: location.name,
                    location: location.place_formatted,
                    mapbox_id: location.mapbox_id,
                    isVillage: false
                  };
                }))
              )]).pipe(
              map(value => [...value[0], ...value[1]])
            );
          } else {
            return villagesObservable;
          }
        } else {
          return of(searchText);
        }
      }));
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((result) => {
        this.isSearchActive = true;

        if (result === '') {
          return this.villages
            .map((village) => {
              return {
                name: village.name,
                bbox: bbox(polygon(village.cords)),
                center: village.center,
                isVillage: true
              }
            });
        } else if (result instanceof Array) {
          return result;
        } else if (result.isManual) {
          return [{
            name: result.text,
            bbox: undefined,
            center: [result.normalized.lon, result.normalized.lat],
            isVillage: false,
            isManual: true
          }];
        } else {
          return [result];
        }
      })
    );
  };

  getSearchColor(isVillage: boolean): string {
    return isVillage ? 'green' : 'rgba(0, 0, 0, 0.87)';
  }

  onSearchSelected(event: any) {
    if (event.isManual) {
      this.searchSelected.next({bbox: undefined, center: event.center});
    } else if (event.isVillage) {
      this.searchSelected.next({bbox: event.bbox, center: event.center});
    } else {
      this.locationService.getLocation(event.mapbox_id, environment.MAPBOX_ACCESS_TOKEN).subscribe((location) => {
        this.searchSelected.next({bbox: undefined, center: location.center});
      });
    }
  }

  onLogoutClicked() {
    this.campaignService.selectCampaign(undefined);
    this.villageService.clear();
    this.cookieService.deleteAll('/');
    this.router.navigate(['/auth']);
  }
}

function splitText(text: string) {
  // Check if the text contains a comma
  if (text.includes(',')) {
    // Split by comma
    return text.split(',');
  } else {
    // Split by whitespace (one or more spaces, tabs, etc.)
    return text.split(/\s+/);
  }
}

const isLatLong = (text: string): boolean => {
  const ddRegex = /^-?\d{1,3}\.\d+$/;
  const dmsRegex = /^(\d{1,3})°\s?(\d{1,2})'\s?(\d{1,2}(\.\d+)?)"\s?[NSWE]$/;

  const coords = splitText(text);

  if (coords.length === 2) {
    const [lat, lon] = coords;

    const isDDLat = ddRegex.test(lat.trim()) && Math.abs(parseFloat(lat.trim())) <= 90;
    const isDDLon = ddRegex.test(lon.trim()) && Math.abs(parseFloat(lon.trim())) <= 180;

    const isDMSLat = dmsRegex.test(lat.trim());
    const isDMSLon = dmsRegex.test(lon.trim());

    return (isDDLat && isDDLon) || (isDMSLat && isDMSLon);
  }

  return false;
};

const convertToDd = (text: string): { lat: number, lon: number } => {
  const ddRegex = /^-?\d{1,3}\.\d+$/;
  const dmsRegex = /^(\d{1,3})°\s?(\d{1,2})'\s?(\d{1,2}(\.\d+)?)"\s?[NSWE]$/;

  const coords = splitText(text);

  if (coords.length !== 2) throw new Error('Invalid coordinate format');

  const [lat, lon] = coords;

  const dmsToDd = (dms: string): number => {
    const regex = /^(\d{1,3})°\s?(\d{1,2})'\s?(\d{1,2}(\.\d+)?)"\s?[NSWE]$/;
    const match = dms.match(regex);
    if (!match) throw new Error('Invalid DMS format');

    const [, degrees, minutes, seconds, , direction] = match;
    let dd = parseFloat(degrees) + parseFloat(minutes) / 60 + parseFloat(seconds) / 3600;

    if (direction === 'S' || direction === 'W') {
      dd = -dd;
    }

    return dd;
  };

  const convertCoord = (coord: string): number => {
    if (ddRegex.test(coord)) return parseFloat(coord);
    if (dmsRegex.test(coord)) return dmsToDd(coord);
    throw new Error('Invalid coordinate format');
  };

  return {
    lat: convertCoord(lat.trim()),
    lon: convertCoord(lon.trim()),
  };
};
